export const dynamicUrl = (url) => {
  if (url) {
    if (
      url.includes("/promo-dan-penawaran") &&
      !url.endsWith("/promo-dan-penawaran")
    ) {
      return "/promo-dan-penawaran/[slug]";
    } else if (url.includes("/rewards") && !url.endsWith("/rewards")) {
      return "/rewards/[slug]";
    } else if (url.includes("/info/") && !url.endsWith("/info/")) {
      return "/info/[slug]";
    } else {
      return url;
    }
  } else {
    return url;
  }
};
